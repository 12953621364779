<template>
  <div>
    <NavBar></NavBar>
    <div  class="container" >
      <div class="mt-4">
        <h1>My Courses</h1>
        <p>Here is the list of courses you have purchased:</p>
        <hr>
        <Loader v-if="loading"></Loader>
        <div v-else>
          <div class="text-center mb-5 mt-5" v-if="isAnonymous">
            <!-- Anon user-->
            <p>To see your courses you have to login first: <b-button :to="{ name: routeNames.LOGIN}" variant="info">Login</b-button></p>

            <p>Please also take a look at our cute gopher products and our courses : </p>
            <b-button :to="{ name: routeNames.PRODUCTS}" variant="success">Products</b-button>
            <br>
            <b-button class="mt-3" :to="{ name: routeNames.TUTORIAL,params : {tutorialId: this.tutorialId, slug: 'build-ecommerce-website-golang-vuejs-gin-aws-lambda'}}" variant="success">Tutorial</b-button>
          </div>
          <div class="text-center mb-5 mt-5" v-if="this.tutorials.length === 0 && !isAnonymous">
              <p>You do not have any courses yet !</p>
              <p>Take a look at our cute gopher products and our courses : </p>
              <b-button :to="{ name: routeNames.PRODUCTS}" variant="success">Products</b-button>
              <br>
              <b-button class="mt-3" :to="{ name: routeNames.TUTORIAL,params : {tutorialId: this.tutorialId, slug: 'build-ecommerce-website-golang-vuejs-gin-aws-lambda'}}" variant="success">Tutorial</b-button>
          </div>
          <div v-else class="row" v-for="(tutorial, idx) in tutorials" :key="idx">
            <div class="col">
              <h3>{{tutorial.name}}</h3>
              <p v-html="tutorial.shortDescription"></p>
              <router-link :to="{name: routeNames.COURSE_DETAIL, params : {id: tutorial.id}}">
                <b-button variant="success">Go to course</b-button>
              </router-link>
              <hr>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import NavBar from "@/components/NavBar";
import Loader from "@/components/Loader";
import AuthHelper from "@/authHelper";
import Webservice from "@/webservice";
import Consts from "@/consts";

export default {
  name: "MyCourses",
  components: {Loader, NavBar},
  data(){
    return {
      tutorials: null,
      loading: false,
      routeNames: Consts.ROUTE_NAMES,
      tutorialId: process.env.VUE_APP_MAIN_TUTORIAL_ID,
      isAnonymous: false
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData(){
      this.loading = true
      AuthHelper.getCurrentUser(false).then((user) => {
            if (user.anonymous) {
              this.isAnonymous = true
            }
            Webservice.getMyCourses(user.idToken).then((res) => {
              this.tutorials = res.data
              this.loading = false
            }).catch((err)=>{
              console.error(err)
              this.loading = false
              this.makeToast("Error !", "An error occurred, please retry later", "danger")
            })
      }).catch((err)=>{
        console.error(err)
        this.loading = false
        this.makeToast("Error !", "An error occurred, please retry later", "danger")
      })
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: 5000,
        appendToast: true
      })
    },

  }

}
</script>

<style scoped>

</style>